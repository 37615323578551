import { required, confirmed, length, email } from "vee-validate/dist/rules";
import { extend, configure } from "vee-validate";
import { i18n } from "./i18n";

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    values._field_ = "";

    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});
extend("required", required);

extend("email", {
  ...email,
});

extend("confirmed", {
  ...confirmed,
});

extend("length", {
  ...length,
});
