<template>
  <div class="siglediv">
    {{ strLabel }}
    <div v-if="!blnImgUploading">
      <b-field class="file" :class="{ 'has-name': !!file }">
        <b-upload @input="handleUpload" v-model="file" expanded>
          <a class="button -orange is-fullwidth">
            <i class="fas fa-upload"></i> &nbsp;&nbsp;
            <span> {{ $t("CLICK_TO_UPLOAD") }}</span>
            <span v-if="file">
              {{ file.name }}
            </span>
          </a>
        </b-upload>
      </b-field>
    </div>
    <div v-else>
      <span v-if="fltPercentCompleted < 100">
        <b-progress :value="fltPercentCompleted" show-value></b-progress>
      </span>
      <span v-else> {{ $t("FILE_UPLOADED") }}!</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "BUploader",
  props: {
    strPhotoNumber: {
      type: String,
      default: "1",
      required: false,
    },
    strLabel: {
      type: String,
      default: "Upload",
      required: false,
    },
  },
  data() {
    return {
      file: null,
      blnHaveSignedUrl: false,
      fltPercentCompleted: 0,
      blnImgUploading: false,
    };
  },
  methods: {
    async handleUpload() {
      let file = this.file;
      let strContentType = file.type;
      this.blnImgUploading = true;
      let objPresignedPost = await this.$utils.getSignedUrl(strContentType);
      if (!objPresignedPost) {
        alert("Error: Could not get S3 URL");
        this.blnHaveSignedUrl = false;
        return;
      } else {
        this.blnHaveSignedUrl = true;
      }

      const formData = new FormData();
      formData.append("Content-Type", strContentType);

      Object.entries(objPresignedPost.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);

      const instance = axios.create();
      this.blnImgUploading = true;
      const res = await instance.post(objPresignedPost.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          return data;
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.fltPercentCompleted = percentCompleted;
          // console.log(percentCompleted / 100);
        },
      });
      //this.blnImgUploading = false;

      // console.log('The headers are ', res.headers);
      const location = res.headers.location; // get the final url of our uploaded image
      let strImgUrl = decodeURIComponent(location);

      if (strImgUrl) {
        let arrTemp = strImgUrl.split("/");
        // console.log('arrTemp is', arrTemp)
        if (arrTemp.length > 1) {
          let strFilename = arrTemp[arrTemp.length - 1];
          let strPhotoNumber = this.strPhotoNumber;
          this.$emit("uploaded", { strPhotoNumber, strFilename });
          this.$emit("input", strFilename);
        }
      } else {
        window.alert("Document could not be uploaded, please try again!");
      }
    },
  },
};
</script>
