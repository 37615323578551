<template>
  <section >
   <!-- <div class="row justify-between head">
      <h4>Upload Avatar</h4>
      <b-button
          flat
          icon="close"
          class="closeBtn fs12px"
          round
          dense
          v-close-popup
        /> 
    </div>-->

    <div class="w100 uploadfile">
      
      <form @submit="save"  class="w100 kycform">
        <!-- {{ objModel }} -->
        <div class="row justify-between head"><h4>{{ $t('UPLOAD_AVATAR') }}</h4></div>

        <div class="body ">
          <div class="inneruploader">
            <div class="siglediv" v-if="objModel.kyc_type == 'WEBC'">
              <!-- webcam -->
              <WebcamUploader
                v-model="strWebcamImgData"
                @uploaded="save"
              ></WebcamUploader>
            </div>
            <div v-else>
              <BUploader
                :arrAllowed="['image']"
                strLabel="Upload your image"
                v-model="strFileName"
                @uploaded="save"
              />
            </div>
          </div>
        </div>

        <!-- <div class="foot">
            <b-button
              size="is-small"
              label="Cancel"
              v-close-popup
              class="ml5px bglightgrey"
            />
          </div> -->
      </form>
    </div>
  </section>
</template>

<script>
import BUploader from "./inputs/BUploader.vue";

export default {
  props: ["strUserId", "objSelectedModel", "strView"],
  components: { BUploader },
  computed: {},

  data() {
    return {
      file: null,
      strFileName: "",
      strTitle: "Change Avatar",
      objModel: {},
      strResourceUrl: "save-avatar",
      blnLoaded: true,
      isLoading: false,
    };
  },
  created() {},
  methods: {
    async save({ strFilename, strPhotoNumber }) {
      this.blnLoaded = false;

      //   alert(strFilename);

      let objModel = { file_name: strFilename };

      let strUrl = `/u/${this.strResourceUrl}`;

      try {
        const objRequest = {
          objModel,
        };
        let strAction = "";

        let objData = await this.$myAxios.put(strUrl, objRequest);
        if (objData.blnOK) {
          //alert("Uploaded");

          this.$store.commit("setAvatar", strFilename);
          this.$utils.showSuccessNotice("Uploaded");
        } else {
          console.log(objData.strMessage);
          this.$utils.showErrorNotice("Error");
        }

        this.$emit("close");
      } catch (e) {
        console.log(e);
      } finally {
        this.blnLoaded = true;
      }
    },
  },
};
</script>
