<template>
  <div>
    <div class="headcount">{{ $t("DRAW_CLOSES_IN") }}</div>
    <div class="countdowndiv">
      <div class="innerDiv">
        <!-- Draw in -->
        <template v-if="objCountdown">
          <div v-if="objCountdown.intHours > 0">
            {{ objCountdown.strHours }} <span>hours</span>
          </div>

          <div>{{ objCountdown.strMinutes }} <span>minutes</span></div>
          <div>{{ objCountdown.strSeconds }} <span>seconds</span></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    objLottoConfig: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startCountdown();
  },

  data() {
    return {
      objNextDraw: {},
      obj1MinuteLater: null,
      blnLoaded: false,
      strGroup: "",
      intBetIndex: null,
      intNumDraws: 1,
      intStakeAmount: 10,
      objCloseDateTime: null,
      intIntervalId: null,
      objCountdown: {
        strDays: "-",
        strHours: "-",
        strMinutes: "-",
        strSeconds: "-",
        intHours: 0,
      },
      intIntervalId: null,
      blnNoDrawsAvail: false,
    };
  },
  methods: {
    stopTimer() {
      clearInterval(this.intIntervalId);
    },
    startCountdown() {
      let objLottoConfig = this.objLottoConfig;

      try {
        if (objLottoConfig.arrOpenDraws.length == 0) {
          //alert("Retining");
          return;
        }
      } catch (e) {
        console.log(e);
      }
      let objNextDraw = this.getNextOpenDraw();

      if (!objNextDraw) {
        this.blnNoNextDraws = true;
        this.stopTimer();
        return;
      }

      let strCloseDateTime = objNextDraw.close_datetime;
      this.objCloseDateTime = dayjs(strCloseDateTime);
      this.objNextDraw = objNextDraw;
      this.startTimer();
    },
    getNextOpenDraw() {
      for (let objDraw of this.objLottoConfig.arrOpenDraws) {
        let strCloseDateTime = objDraw.close_datetime;
        let objCloseDateTime = dayjs(strCloseDateTime);
        let objNow = dayjs();
        if (objNow.isAfter(objCloseDateTime)) {
          continue;
        }

        return objDraw;
      }

      return null;
    },

    startTimer() {
      this.stopTimer();
      this.updateCountdown();
      this.intIntervalId = window.setInterval(this.updateCountdown, 1000);
    },
    stopTimer() {
      if (this.intIntervalId) {
        window.clearInterval(this.intIntervalId);
      }
    },
    updateCountdown() {
      let objEndDate = this.objCloseDateTime;
      let objNow = dayjs();
      //objEndDate = this.obj1MinuteLater;
      if (objNow.isAfter(objEndDate)) {
        //alert("loading again");
        console.log("reloading");
        this.startCountdown();
        //console.log("retnrning");
        //console.log(objNow.format(), objEndDate.format());
        return;
      }
      let intDays = objEndDate.diff(objNow, "days");
      objNow = objNow.add(intDays, "days");
      let intHours = objEndDate.diff(objNow, "hours");
      objNow = objNow.add(intHours, "hours");
      let intMinutes = objEndDate.diff(objNow, "minutes");
      objNow = objNow.add(intMinutes, "minutes");
      let intSeconds = objEndDate.diff(objNow, "seconds");
      //return str
      let strDays, strHours, strMinutes, strSeconds;
      strDays = intDays;
      if (intDays < 10) {
        strDays = "0" + intDays;
      }
      strHours = intHours;
      if (intHours < 10) {
        strHours = "0" + intHours;
      }
      strMinutes = intMinutes;
      if (intMinutes < 10) {
        strMinutes = "0" + intMinutes;
      }
      strSeconds = intSeconds;
      if (intSeconds < 10) {
        strSeconds = "0" + intSeconds;
      }
      this.objCountdown = {
        strDays,
        strHours,
        strMinutes,
        strSeconds,
        intHours,
      };
    },
    createBet(intNumLines) {
      let arrLines = [];
      for (let i = 0; i < intNumLines; i++) {
        let objLine = this.$utils.getNewLine(this.strGroup, this.arrPools);
        arrLines.push(objLine);
      }

      let objBet = {
        arrLines,
        strLottoId: this.strLottoId,
        strGroup: this.strGroup,
        strStatus: "p", //incomplete/pending bets
      };

      this.$store.commit("addBet", objBet);
      this.intBetIndex = this.arrBets.length - 1;
    },
  },
};
</script>
