import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import utils from "@/modules/utils";

import getRoutes from "./routes";
function getRouter({ store, ssrContext }) {
  const arrRoutes = getRoutes({ store });

  const router = new VueRouter({
    mode: "hash",

    // scrollBehavior(to, from, savedPosition) {
    //   // always scroll to top
    //   // console.log('scrolling to top');
    //   return { top: 0 };
    // },

    routes: arrRoutes,
  });

  router.beforeEach((to, from, next) => {
    store.state.blnLoading = true;
    next();
  });

  router.afterEach((to, from, next) => {
    // console.log('after');
    // window.scrollTo(0, 0);
    // window.parent.scrollTo(0, 0);
    utils.emitWCEvent(Vue.prototype, "scrollToTop");
    store.state.blnLoading = false;
    // window.setTimeout(() => {
    //   store.state.blnLoading = false;
    // }, 2000);
  });

  // console.log(router);
  return router;
}

export { getRouter };
