import Vue from "vue";

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import messages from "./i18n/index.js";

const i18n = new VueI18n({
  locale: "fa",
  messages,
});
export { i18n };
