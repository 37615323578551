import MainLayout from "../layouts/MainLayout.vue";
import GamesListLayout from "../layouts/GamesListLayout.vue";
export default function getRoutes({ Vue, store }) {
  const authenticateUser = (to, from, next) => {
    try {
      const blnIsUserLoggedIn = store.getters["blnIsUserLoggedIn"];
      console.log(blnIsUserLoggedIn);
      if (!blnIsUserLoggedIn) {
        console.log("redirecting", to);
        location.href = "/login?to=" + to.fullPath;
        return;
        // return next({
        //   name: "SignIn",
        //   params: { strTarget: to.fullPath },
        // });
      }
    } catch (e) {
      console.log(e);
      location.href = "/login?to=" + to.fullPath;
      return;
      // return next({
      //   name: "SignIn",
      // });
    }
    next();
  };

  const routes = [
    {
      path: "/games-list",
      name: "",
      component: GamesListLayout,
      children: [
        {
          path: "",
          name: "GamesList",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/GamesList.vue"),
        },
      ],
    },
    {
      path: "/",
      name: "",
      component: MainLayout,
      children: [
        {
          path: "/u",
          component: () =>
            import(/* webpackChunkName: "home" */ "../layouts/UserLayout.vue"),
          beforeEnter: authenticateUser,
          children: [
            {
              path: "checkout",
              name: "CheckoutView",
              component: () =>
                import(
                  /* webpackChunkName: "order" */ "../views/CheckoutView.vue"
                ),
            },
            {
              path: "profile",
              name: "Profile",
              component: () =>
                import(
                  /* webpackChunkName: "profile" */ "../views/Profile.vue"
                ),
            },
            {
              path: "edit-profile",
              name: "EditProfileForm",
              component: () =>
                import(
                  /* webpackChunkName: "profile" */ "../views/EditProfileForm.vue"
                ),
            },

            {
              path: "wallet",
              name: "Wallet",
              component: () =>
                import(/* webpackChunkName: "wallet" */ "../views/Wallet.vue"),
            },

            {
              path: "deposit",
              name: "Deposit",
              props: true,
              component: () =>
                import(/* webpackChunkName: "wallet" */ "../views/Deposit.vue"),
            },
            {
              path: "deposit-new",
              name: "DepositNew",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "picker" */ "../views/DepositNew.vue"
                ),
            },

            {
              path: "withdrawal",
              name: "Withdrawal",
              component: () =>
                import(
                  /* webpackChunkName: "wallet" */ "../views/Withdrawal.vue"
                ),
            },

            {
              path: "notifications",
              name: "Notifications",
              component: () =>
                import(
                  /* webpackChunkName: "notifications" */ "../views/Notifications.vue"
                ),
            },
            {
              path: "my-bets",
              name: "MyBets",
              component: () =>
                import(/* webpackChunkName: "mybets" */ "../views/MyBets.vue"),
            },
            {
              path: "order-confirmation",
              name: "OrderConfirmation",
              component: () =>
                import(
                  /* webpackChunkName: "order" */ "../views/OrderConfirmation.vue"
                ),
            },

            {
              path: "checkout-deposit",
              name: "CheckoutDeposit",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "order" */ "../views/CheckoutDeposit.vue"
                ),
            },

            {
              path: "aviator",
              name: "Aviator",
              component: () =>
                import(
                  /* webpackChunkName: "aviator" */ "../views/Aviator.vue"
                ),
            },
            {
              path: "turbo",
              name: "Turbo",
              component: () =>
                import(/* webpackChunkName: "Turbo" */ "../views/Turbo.vue"),
            },
            {
              path: "turbo-redirect",
              name: "TurboRedirect",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/TurboRedirect.vue"
                ),
            },
            {
              path: "hotlineplay",
              name: "HotLinePlay",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/HotLinePlay.vue"
                ),
            },
            {
              path: "rolldiceplay",
              name: "RollDicePlay",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/RollDicePlay.vue"
                ),
            },
            {
              path: "hiloplay",
              name: "HiloPlay",
              component: () =>
                import(/* webpackChunkName: "Turbo" */ "../views/HiloPlay.vue"),
            },
            {
              path: "minirouletteplay",
              name: "MiniRoulettePlay",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/MiniRoulettePlay.vue"
                ),
            },
            {
              path: "minesplay",
              name: "MiniesPlay",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/MiniesPlay.vue"
                ),
            },
            {
              path: "plinkoplay",
              name: "PlinkoPlay",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/PlinkoPlay.vue"
                ),
            },

            {
              path: "keno",
              name: "Keno",
              component: () =>
                import(/* webpackChunkName: "keno" */ "../views/Keno.vue"),
            },
            {
              path: "comingsoon",
              name: "ComingSoon",
              component: () =>
                import(
                  /* webpackChunkName: "Turbo" */ "../views/ComingSoon.vue"
                ),
            },

            {
              path: "congratulations",
              name: "CongratulationsPage",
              component: () =>
                import(
                  /* webpackChunkName: "picker" */ "../views/CongratulationsPage.vue"
                ),
            },

            {
              path: "logout",
              name: "Logout",
              component: () =>
                import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
            },
            {
              path: "logged-off",
              name: "LoggedOff",
              component: () =>
                import(
                  /* webpackChunkName: "logout" */ "../views/LoggedOff.vue"
                ),
            },
          ],
        },
        {
          path: "",
          name: "Home",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
        },

        {
          path: "forgot-password",
          name: "ForgotPassword",
          component: () =>
            import(
              /* webpackChunkName: "password" */ "../views/ForgotPassword.vue"
            ),
        },

        {
          path: "sign-in",
          name: "SignIn",
          props: true,
          component: () =>
            import(/* webpackChunkName: "signin" */ "../views/SignIn.vue"),
        },
        {
          path: "login",
          name: "Login",
          props: true,
          component: () =>
            import(/* webpackChunkName: "signin" */ "../views/SignIn.vue"),
        },

        {
          path: "register",
          name: "Register",
          props: true,
          component: () =>
            import(/* webpackChunkName: "register" */ "../views/Register.vue"),
        },

        {
          path: "all-results",
          name: "AllResults",
          props: true,
          component: () =>
            import(/* webpackChunkName: "results" */ "../views/AllResults.vue"),
        },

        {
          path: "cart",
          name: "Cart",
          component: () =>
            import(/* webpackChunkName: "cart" */ "../views/Cart.vue"),
        },

        {
          path: "redirector",
          name: "Redirector",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Redirector.vue"),
        },

        // {
        //   path: "reset-password",
        //   name: "ResetPassword",
        //   component: () =>
        //     import(/* webpackChunkName: "home" */ "../views/ResetPassword.vue"),
        // },
        {
          path: "reset-password",
          name: "ResetPassword",
          component: () =>
            import(
              /* webpackChunkName: "password" */ "../views/ResetPassword.vue"
            ),
        },
        {
          path: "verify-email",
          name: "VerifyEmail",
          component: () =>
            import(
              /* webpackChunkName: "password" */ "../views/VerifyEmail.vue"
            ),
        },
        {
          path: "sign-up-done",
          name: "SignUpDone",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "register" */ "../views/SignUpDone.vue"
            ),
        },
        {
          path: "pick",
          name: "PickNumbers",
          props: true,
          component: () =>
            import(/* webpackChunkName: "picker" */ "../views/PickNumbers.vue"),
        },
      ],
    },

    // Always leave this as last one,
    // but you can also remove it
    {
      path: "*",
      component: () => import("../views/Cart.vue"),
    },
  ];

  return routes;
}
