import { faPray } from "@fortawesome/free-solid-svg-icons";
import utils from "../modules/utils";
//import { accessToken } from "../composables/auth";
import axios from "axios";
const utilsPlugin = {
  install(Vue, objOptions) {
    let { store, router, i18n } = objOptions;

    utils.showSnackbar = (strMessage) => {
      //alert(strMessage);
      //return
      Vue.prototype.$buefy.snackbar.open({
        duration: 5000,
        message: strMessage,
        container: "div#app",
        position: "is-bottom-right",
      });
    };
    utils.CopyToClipboard = (e) => {
      navigator.clipboard.writeText(e.innerText);
    };
    utils.dropDownOptions = {
      showDialCodeInList: true,
      showDialCodeInSelection: true,
      showFlags: true,
    };
    utils.inputOptions = {
      showDialCode: false,
      placeholder: "Enter your phone number",
      required: true,
      autocomplete: "off",
      type: "tel",
    };

    utils.getCountry = (strBrandingId) => {
      let strCode = "";
      switch (strBrandingId) {
        case "ja":
          strCode = "AW";
          break;
        case "lottochi":
          strCode = "IR";
          break;
        case "888drc":
          strCode = "CD";
          break;
      }

      return strCode;
    };

    utils.getRandomString = (intLength) => {
      return Array(intLength + 1)
        .join((Math.random().toString(36) + "00000000000000000").slice(2, 18))
        .slice(0, intLength);
    };
    utils.getUserRequestStatus = (strVal) => {
      let obj = utils.arrUserRequestStatus.find((obj) => obj.value == strVal);
      if (obj) return obj.label;
      return "";
    };
    utils.arrUserRequestStatus = [
      {
        label: "SUBMITTED",
        value: "r",
      },
      {
        label: "APPROVED",
        value: "a",
      },
      {
        label: "DECLINED",
        value: "d",
      },
      {
        label: "",
        value: "n",
      },
    ];
    utils.getUserRequestStatusColor = (strVal) => {
      let str = "";
      switch (strVal) {
        case "r":
          str = "warning";
          break;
        case "a":
          str = "positive";
          break;
        case "d":
          str = "negative";
          break;
        case "n":
          str = "negative";
          break;
      }
      return str;
    };
    utils.getUploadedFileUrl = (strFileName) => {
      return (
        "https://s3.eu-west-1.amazonaws.com/imprexis-banners-bucket/" +
        strFileName
      );
    };

    utils.getNewLine = (strGroup, arrPools) => {
      // alert(arrPools);
      let arr = [];
      for (let i = 0; i < arrPools.length; i++) {
        arr.push({ arrNumbers: [], ...arrPools[i] });
      }
      let strStatus = "nok";

      return { arrNumbers: [], arrPools: arr, strGroup, strStatus };
    };

    //intNumbersToPick is the numbers needed to be picked for
    //this lotto line
    utils.isLineOK = (objLine) => {
      let { arrPools } = objLine;
      if (arrPools.length == 0) {
        return false;
      }

      for (let objPool of arrPools) {
        let intNumbersToPick = parseInt(objPool.intNumbersToPick);
        if (isNaN(intNumbersToPick)) {
          intNumbersToPick = 0;
        }
        if (objPool.arrNumbers.length < intNumbersToPick) {
          return false;
        }

        for (let intNumber of objPool.arrNumbers) {
          if (intNumber == null) {
            return false;
          }
        }
      }

      return true;
    };

    utils.arePoolsOK = (arrPools) => {
      for (let objPool of arrPools) {
        let { intNumbersToPick } = objPool;
        if (objPool.arrNumbers.length == 0) {
          return false;
        }

        if (objPool.arrNumbers.length < intNumbersToPick) {
          return false;
        }

        for (let intNumber of objPool.arrNumbers) {
          if (intNumber == null) {
            return false;
          }
        }
      }

      return true;
    };

    utils.arrKYCType = [
      {
        label: "ID or Passport",
        value: "ID",
      },
      {
        label: "Webcam photo",
        value: "WEBC",
      },
      // {
      //   label: "Proof of Address",
      //   value: "POA",
      // },
      // {
      //   label: "Source of funds",
      //   value: "SOF",
      // },
      // {
      //   label: "Credit Card",
      //   value: "CC",
      // },
    ];

    utils.showErrorNotice = (strMessage) => {
      utils.showSnackbar(strMessage);
    };

    utils.handleS3Upload = async (file) => {
      let objPresignedPost = await utils.getSignedUrl();
      if (!objPresignedPost) {
        throw new Error("Presigned url not available");
      }
      const formData = new FormData();
      formData.append("Content-Type", file.type);

      Object.entries(objPresignedPost.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);

      const instance = axios.create();
      const res = await instance.post(objPresignedPost.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          return data;
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.fltPercentCompleted = percentCompleted;
          // console.log(percentCompleted / 100);
        },
      });
      this.blnImgUploading = false;

      // console.log('The headers are ', res.headers);
      const location = res.headers.location; // get the final url of our uploaded image
      let strImgUrl = decodeURIComponent(location);

      if (strImgUrl) {
        let arrTemp = strImgUrl.split("/");
        // console.log('arrTemp is', arrTemp)
        if (arrTemp.length > 1) {
          let strImgName = arrTemp[arrTemp.length - 1];
          this.$emit("uploaded", strImgName);
          this.$emit("input", strImgName);
        }
      } else {
        window.alert("Image could not be uploaded, please try again!");
      }
    };

    utils.getCurrency = (strBrandingId) => {
      return "K";
    };
    utils.formatCurrency = (strBrandingId, strCurrency) => {
      if (strBrandingId == "888drc") {
        if (strCurrency == "CDF") {
          return "FC";
        }
        if (strCurrency == "K") {
          return "K";
        }
        if (strCurrency == "ZMW") {
          return "K";
        }
        return strCurrency;
      }
      if (strBrandingId == "hs") {
        return "K";
      }

      return "$";
    };

    utils.BET_STATUS = {
      PLACED: "pl",
      LOST: "lost",
      WON: "won",
      PENDING: "pen",
      VOIDED: "void",
      CANCELLED: "canc",
    };

    utils.getBetStatus = (strStatus) => {
      if (!strStatus) {
        strStatus = "n/a";
      }
      if (strStatus == "pl") {
        strStatus = "PLACED";
      } else if (strStatus == "lo") {
        strStatus = "LOST";
      } else if (strStatus == "wo") {
        strStatus = "WON";
      } else if (strStatus == "pen") {
        strStatus = "PENDING";
      } else if (strStatus == "voided") {
        strStatus = "VOIDED";
      } else if (strStatus == "canc") {
        strStatus = "CANCELLED";
      }

      strStatus = strStatus.toUpperCase();

      return strStatus;
    };

    utils.getLottosConfigs = async (strLottoId) => {
      let objRequest = {
        arrLottoIds: [
          "pick1",
          "pick2",
          "pick3",
          "pick4",
          "pick5",
          "pick6",
          "five35",
          "six49",
        ],
      };

      let objResponse = await Vue.prototype.$myAxios.post(
        "/lottos-configs",
        objRequest
      );
      //console.log(objResponse);

      return objResponse;
    };

    //TODO:Delete this
    utils.getLottoConfig = async (strLottoId) => {
      let objRequest = {
        arrLottoIds: [
          "pick1",
          "pick2",
          "pick3",
          "pick4",
          "pick5",
          "pick6",
          "five35",
          "six49",
        ],
      };

      let objResponse = await Vue.prototype.$myAxios.post(
        "/lottos-configs",
        objRequest
      );
      //console.log(objResponse);

      return objResponse;
    };
    utils.getAsset = (strAssetName) => {
      var images = require.context("../assets/", false);

      return images("./" + strAssetName);
    };

    utils.getAttributeVal = (strKey, objSettings) => {
      if (!objSettings) {
        return "https://support.hotspot.co.zm/support/tickets/new";
      }

      let objVal = objSettings.branding_attr_vals.find(
        (obj) => (obj.attr_type = strKey)
      );
      if (objVal) {
        return objVal.value;
      }

      return "n.a";
    };

    utils.getSignedUrl = async (strContentType) => {
      let strUrl = "/u/gsu";
      let objResponse = await Vue.prototype.$myAxios.get(strUrl, {
        params: { strContentType },
      });
      if (objResponse.blnOK) {
        return objResponse.obj;
      }
      return null;
    };

    utils.getAvatarFileName = (objUser) => {
      let strAvatarFileName = objUser.avatar_filename;
      if (strAvatarFileName != "") {
        let strUrl =
          "https://s3.eu-west-1.amazonaws.com/imprexis-banners-bucket/";
        strUrl += strAvatarFileName;
        return strUrl;
      }
      var images = require.context("../assets/", false);
      return images("./" + "jackpotaruba.png");
    };

    utils.RECAPTCHA_SITE_KEYS = {
      "888drc": "6LeBS88oAAAAAMDfhWuUcbxVfgQrhz851dxSfsZL",
      hs: "6Lcy1xkpAAAAAI_MCb0-c-rXAn6rSgVSUwxqY7Cp",
    };
    utils.getSiteKey = (strBrandingId) => {
      return utils.RECAPTCHA_SITE_KEYS[strBrandingId];
    };

    utils.getFlag = (strBrandingId) => {
      var images = require.context("../assets/", false);

      //../assets/drcflag.png
      let strFilename = "";
      switch (strBrandingId) {
        case "888drc":
          strFilename = "drcflag.png";
          break;
        case "hs":
          strFilename = "zmflag.png";
          break;
      }
      return images("./" + strFilename);
    };
    utils.getCDialCode = (strBrandingId) => {
      let strDialCode = "";
      switch (strBrandingId) {
        case "888drc":
          strDialCode = "243";
          break;
        case "hs":
          strDialCode = "260";
          break;
      }
      return strDialCode;
    };

    utils.loadWalletStats = async () => {
      console.log("utils.loadWalletSTATS");
      try {
        let objResponse = await Vue.prototype.$myAxios.get("/u/wallet-stats");
        if (objResponse.blnOK) {
          let objWalletStats = objResponse.obj;
          //console.log("Setting wallet stats");

          store.commit("setWalletStats", objWalletStats);
        }
      } catch (e) {
        console.log(e);
      }
    };

    Vue.prototype.$utils = utils;
  },
};

export default utilsPlugin;
