<template>
  <div>
    <!-- glayout -->
    <div :class="getClass()">
      <div class="maincolumns">
        <div class="contentdiv lotterydiv bgnone">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getClass() {
      let strClass = "mainbody";
      if (this.objUser && this.objUser.id) {
        strClass += " logged_in ";
      } else {
        strClass += " notloggedin ";
      }
      if (this.$strBrandingId == "888drc") {
        strClass += " drc ";
      }
      if(this.$strBrandingId == "hs") {
        strClass += " hs ";
      }
      return strClass;
    },
  },
};
</script>
<style></style>
